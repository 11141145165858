import React from "react";
import styled, { css } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "components/Layout/Layout";

import TopBar from "../components/shared/TopBar";

import { media } from "utils/Media";

import snowFlakesXs from "../images/snowflakes-mobile.svg";
import snowFlakesXl from "../images/snowflakes-desktop.svg";

import VanBackground from '../images/hygge/hygge-background.png'

const SnowFlakes = styled.div`
  background: url("${snowFlakesXs}") repeat 50% 120%;
  background-size: 240%;

  @media ${media.sm} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 290%;
  }

  @media ${media.md} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.lg} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.xl} {
    background: url("${snowFlakesXl}") no-repeat 50% 70%;
    background-size: 200%;
  }

  .narrow {
    max-width: 750px;
    margin: 0 auto;
  }
`;

const StyledPages = styled.section`
  width: 100%;
  h2, h3 {
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
  }
  p {
    text-align: center;
  }
  .smaller {
    font-size: 9px;
    line-height: 9px;
  }
  .intro {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;
    max-width: 1100px;
    h2, h3 {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 1rem;
    }
    h2 {
      @media ${media.sm} {
        font-size: 34px;
      line-height: 34px;
      }
      @media ${media.lg} {
        font-size: 40px;
      line-height: 40px;
      }
    }
    h3 {
      @media ${media.sm} {
        font-size: 30px;
      line-height: 30px;
      }
    }
    p {
      margin-bottom: 1rem;
    }
    h3 span {
      margin: .85rem 0;
      display: block;
    }
    @media ${media.md} {
      padding: 4rem 0 1rem 0 ;
    }
  }
  .banner-image {
    width: 90%;
    margin: 0 auto;
    text-align:center;
    margin-bottom: 1rem;
    .desktop {
      display: none;
    }
    @media ${media.sm} {
      .mobile {
          display: none;
      }
      .desktop {
          display: block;
          max-width: 1360px;
          margin: 0 auto;
      }
    }
  }
  .wrapper {
    width: 90%;
    margin: 0 auto;
    text-align:center;
    @media ${media.sm} { 
    margin: 2rem auto;
    }
    @media ${media.md} { 
    margin: 4rem auto;
    }
    .flavours {
      p {
        margin-bottom: 2rem;
        &:nth-child(1) {
          span {
            color: #AE0A32;
          }
        }
        &:nth-child(2) {
          span {
            color: #7E0B91;
          }
        }
        &:nth-child(3) {
          span {
            color: #A23C1C;
          }
        }
        
          @media ${media.sm} {
          font-size: 20px;
          line-height: 20px;
          }
          @media ${media.lg} {
            font-size: 22px;
          line-height: 22px;
          }
        
        span {
          font-size: 24px;
          line-height: 24px;
          font-family: ${props => props.theme.font.family.special};
          @media ${media.sm} {
            font-size: 30px;
          line-height: 30px;
          }
          @media ${media.lg} {
            font-size: 40px;
          line-height: 40px;
          }
        }
      }
    }
    @media ${media.md} {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 2rem;
      
    } 
  }
  .hygge-container {
    width: 90%;
    margin: 1rem auto;
    text-align: center;
    max-width: 1100px;
          margin: 0 auto;
  }
  .bg-image {
    background-image: url('${VanBackground}');
    /* background-repeat: repeat; */
    padding: .85rem;
    @media ${media.md} {
      margin-top: 2rem;
    }
    .wrapper {
      background: #fff;
      padding: 1rem;
      display: flex;
      flex-direction: column-reverse;
      .text {
        padding: 1rem 0;
        h3 {
          font-size: 32px;
          line-height: 32px;
          @media ${media.sm} {
            font-size: 38px;
          line-height: 38px;
          }
          @media ${media.lg} {
            font-size: 40px;
          line-height: 40px;
          }
          @media ${media.md} {
            flex-basis: 60%;
          }
        }
      }
      @media ${media.md} {
        flex-direction: row;
        max-width: 1360px;
        .image {
          /* flex-basis: 40%; */
          .gatsby-image-wrapper {
            width: 200px;
          }
        }
      }
      @media ${media.lg} {
        .image {
          .gatsby-image-wrapper {
            width: 425px;
          }
        }
      }
      @media ${media.xl} {
        .image {
          .gatsby-image-wrapper {
            width: 550px;
          }
        }
      }
      
    }
  }
  .terms {
    width: 90%;
    margin: 2rem auto;
    max-width: 1000px;
  }
`;

export default function HyggeT4() {
  return (
    <Layout
      pageName="hygget4"
      slug="hygget4"
      title="Hygge T4"
      description="Find out performance times, ticket prices, access and other information for Frozen the Musical at London’s Theatre Royal Drury Lane from August 2021"
    >
      <TopBar pageName="hygge" />
      <SnowFlakes>
        <StyledPages>
          <div className="intro">
            <h2>HOW TO HYGGE WITH FROZEN AND T4 BUBBLE TEA</h2>
            <p>
              We’re all about hygge, the concept of cozy contentment, at
              Disney’s Frozen The Musical! While one might not immediately
              associate the icy landscapes of Arendelle with warmth and comfort,
              ours is a story rich with themes of familial love, friendship, and
              finding joy in simple moments – all quintessential aspects of
              hygge.
            </p>
          </div>
            <div className="banner-image">
              <StaticImage src="../images/hygge/tea-desktop.png" alt="" class="desktop" />
              <StaticImage src="../images/hygge/tea-mobile.png" alt="" class="mobile" />
            </div>
            <div className="hygge-container">
            <p>
            Find your Hygge moment with the Frozen x T4 Hygge Hut as it journeys through London. For a limited time over the Easter holiday break until 12 April our Hygge Hut on wheels will be touring hotspots throughout the capital giving away some very special T4 drinks.
            </p>
            </div>
            <div class="wrapper">
              <div className="flavours">
                <p>
                  Choose from: <br />
                  <span>NORDIC FOREST</span>
                  <br />A berry-licious mulberry milk tea with icy white pearls.
                </p>
                <p>
                  <span>ROYAL TEA</span>
                  <br />A majestic grape flavoured iced tea with aloe vera jelly
                  pearls.
                </p>
                <p>
                  <span>SUMMER BLOWOUT</span> <br />
                  Light and refreshing honey peach iced tea.
                </p>
              </div>
              <div>
                <StaticImage src="../images/hygge/drinks.png" alt="" />
              </div>
            </div>
            <div className="hygge-container">

            <p>
              All the T4 bubble tea drinks are proudly made from the finest
              Taiwanese tea leaves and brewed on-site for the freshest flavours.
              T4 pride themselves on their unprocessed and natural ingredients,
              giving each of their drinks the authentic taste that both bubble
              tea experts and bubble tea newbies love!
            </p>
            <p>
              It can’t be hygge without Frozen The Musical, family, friends…and
              T4 bubble tea!
            </p>
            </div>
            <div className="bg-image">
              <div className="wrapper">
                <div className="image">
                  <StaticImage src="../images/hygge/van.png" alt="" />
                </div>
                <div className="text">
                  <h3>ENTER TO WIN</h3>
                  <p>
                    If you spot the Hygge Hut van around London, make sure to
                    tag @FrozenLondon on Facebook or Instagram with a picture
                    for your chance to win 4 tickets to see Frozen in the West
                    End and win a T4 voucher for one bubble tea a day for a
                    month!{" "}
                  </p>
                  <p>Terms and Conditions apply. See below for more details.</p>
                  <p className="smaller">
                    Open to UK residents (excl. ROI, Channel Islands and Isle of
                    Man) aged 18+. Closes 10am GMT on 15/04/24.  Stay safe –
                    take and upload the photo from a safe location. See full
                    T&Cs below.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="terms">
              <p>
                <strong>Terms and Conditions:&nbsp;</strong>
              </p>
              <p>
                <strong>
                  <em>How to enter:</em>
                </strong>
                <br />
                If you spot the Frozen The Musical T4 Bubble Tea Van within the
                promotion period, take a photo of it and post it to either
                Instagram or Facebook ensuring you tag Frozen London&rsquo;s
                social media profiles (details below) for your chance to win.
                Please ensure that you stop to take the photo in a safe place
                and do not take a picture whilst driving or standing in the road
                or any dangerous place. Photos should be of the Frozen London
                van only. Please see entry restrictions below. If entering on
                Instagram, tag: @frozenlondon If entering on Facebook, tag:
                @frozenlondon One winner will be selected across both social
                media platforms as route of entry, ie. one winner from all
                eligible Instagram and Facebook entries combined.&nbsp;
                <br />
                <br />
                <strong>
                  <em>Start Date:</em>
                </strong>
                &nbsp;Wednesday 3rd April 2024, 10am (UK time)&nbsp;
                <br />
                <br />
                <strong>
                  <em>Closing Date:</em>
                </strong>
                &nbsp;Monday 14th April 2024, no later than 10am (UK time)&nbsp;
                <br />
                <br />
                <strong>
                  <em>Entry Restrictions:</em>
                </strong>
                &nbsp;Entrants must be aged 18 or over and be a resident of the
                United Kingdom&nbsp;<em>excluding</em>&nbsp;Northern Ireland,
                the Republic of Ireland, Channel Islands and Isle of Man. One
                entry per social media channel only, ie. each person can enter a
                maximum of 2 times (once on Instagram and once on
                Facebook).&nbsp;
                <br />
                <br />
                <strong>
                  <em>Prize Details:&nbsp;</em>
                </strong>
                One person will each win 4 x Frozen The Musical tickets and
                a&nbsp;T4 Voucher to the value of XXX. Contents at the sole
                discretion of the Promoters. Tickets to Frozen The Musical must
                be redeemed no later than 15 June for an evening performance.
                Matinees are excluded. Eligible performances include Thursday
                7pm, Friday 7pm, Saturday 7pm, and Sunday 5.30pm from 15 April
                2024 to 15 June 2024. All attendees must be over the age of
                4.&nbsp;
                <br />
                <br />
                <strong>
                  <em>Winner Selection process:&nbsp;</em>
                </strong>
                One winner from the combined entries on Facebook and Instagram
                will be selected at random from all valid entries received via
                those social media channels, ie. one winner will be selected
                from all eligible Instagram and Facebook entries. Each winner
                will receive confirmation that they have won by being tagged in
                a post by the Promoters on the social media channel they entered
                via and were selected from as a winner within ten (10) days of
                the Closing Date. Winners must send @FrozenLondon a private
                message on the relevant social media channel with their contact
                details and postal address within seventy two (72) hours of
                being notified that they are a winner in order to claim their
                prize. If no response is received by this time then the prize
                shall be forfeit and the Promoters reserve the right to award
                the prize to a substitute winner. The Promoters shall have no
                liability in respect of the entry initially selected. The
                Promoter&rsquo;s decision is final and no correspondence will be
                entered into.&nbsp;
                <br />
                &nbsp;
                <br />
                <strong>
                  <em>Prize Fulfilment:</em>
                </strong>
                &nbsp;Winners will receive their prize within 28 (twenty-eight)
                days after claiming their prize. If you are selected as a winner
                your prize will be posted to you, via recorded delivery, without
                prior notification. Please ensure your correct postal address is
                provided in the private message you send to Frozen The Musical
                when claiming your prize. Frozen The Musical will be responsible
                for distributing the prize.&nbsp;
                <br />
                <br />
                <strong>
                  <em>Promoters:</em>
                </strong>
                &nbsp;The Promoters of this Competition are: Disney: The Walt
                Disney Company Limited (Company number 00530051) whose
                registered address is located at 3 Queen Caroline Street,
                London, W6 9PE, United Kingdom&nbsp;
                <br />
                <br />
                AND&nbsp;
                <br />
                <br />
                COMPANY (Company number XXXXXXXX) whose registered address is
                located at XXXXXXXXX <br />
                &nbsp;
                <br />
                <strong>
                  <em>Customer Services/</em>
                </strong>
                &nbsp;
                <strong>
                  <em>Contact Details:</em>
                </strong>
                &nbsp;If you have any questions please contact the Promoters.
                Contact Disney: by emailing at&nbsp;
                <a
                  href="mailto:help@disney.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  help@disney.co.uk
                </a>{" "}
                or write to The Walt Disney Company Limited, 3 Queen Caroline
                Street, London, W6 9PE quoting the name of the competition.
              </p>
              <p>
                <strong>
                  <em>
                    &nbsp;General Terms &amp; Conditions for Prize Draws and
                    Competitions
                  </em>
                </strong>
                &nbsp;By participating you agree to the requirements set out in
                the Competition Rules, the following General Terms and
                Conditions and all promotional materials.
                <br />
                <br />
                <strong>1.</strong> <strong>Eligibility.</strong>
                <br />
                (a) Entrants must meet the Entry Restrictions.
                <br />
                (b) Not open to employees (or their immediate families) of the
                Promoters, their agencies or anyone else professionally
                associated with this competition.
                <br />
                (c) Maximum one entry per person unless otherwise stated.
                Entries via agents or third parties or use of multiple
                identities and email addresses are not permitted. Use of
                automated entries, votes or other programs is prohibited and all
                such entries (or votes) will be disqualified.
                <br />
                (d) Entries must be completed by the Closing Date.
                <br />
                (e)The Promoters reserve the right at any time to request proof
                of identity, to verify eligibilityconditions, or to award any
                prize to the winner in person or a substitute.
                <br />
                <br />
                <strong>2.</strong>{" "}
                <strong>
                  Personal Information.
                  <br />
                </strong>
                (a) Your personal information will be used in accordance with
                The Promoters&rsquo; current published Privacy Policy
                (click&nbsp;
                <a
                  href="https://disneyprivacycenter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                &nbsp;to view Disney&rsquo;s Policy and click here to view&nbsp;
                <a
                  href="https://www.theiceco.co.uk/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Ice Co&rsquo;s
                </a>
                &nbsp;Privacy Policy) and may be passed to our agents to
                administer the competition or send out prizes. We may also use
                your personal information to promote the competition and Disney
                products and services (including the products and services of
                any partner sponsoring or otherwise participating in the
                competition) in any media including, without limitation, TV,
                online and publishing channels and social media platforms.
                <br />
                (b) Personal information will only be used for direct marketing
                purposes if you have consented to receive future marketing from
                the relevant Promoter (you may have already done this if you
                have registered your personal information with the Promoters
                previously).
                <br />
                (c) By entering this competition, entrants agree that their
                information may be used by the Promoters to administer the
                promotion and winners consent to give their name and county for
                the winners&rsquo; list
                <br />
                <br />
                <br />
              </p>
              <p>
                <strong>3.</strong>{" "}
                <strong>
                  Winner Selection.
                  <br />
                </strong>
                (a) The winner(s) will be selected in a random draw to take
                place within 10 working days of the closing date from all
                correct/valid entries received.
                <br />
                (b) The Promoters reserve the right to ask winners to sign a
                written release, request proof of identity and verify the entry
                before awarding any prize.
                <br />
                (c) The Promoters&rsquo; decision to select any winner or
                runner-up is final and we reserve the right not to correspond on
                any matter.
                <br />
                <br />
                <strong>4.</strong>{" "}
                <strong>
                  Prize Confirmation &amp; Acceptance.
                  <br />
                </strong>
                (a) If at any time (i) we require a response from any winner
                before awarding any prize and no response is received after we
                have made reasonable efforts to make contact and within a
                reasonable period of time (or by the dates set out in the
                Competition Rules), or (ii) a winner declines to accept the
                prize; or (iii) the requested documentation stated in the
                Competition Rules is not received Disney in time; or (iv) the
                winner becomes unavailable; The Promoters reserve the right to
                withdraw the prize entitlement and award the prize to a
                substitute winner chosen at the same time as the first name(s)
                were selected or judged to be a winner.
                <br />
                (b) Any prize which is returned or cannot be delivered will be
                used in the Promoters&rsquo; sole discretion for another
                competition or given to charity. Disney has no responsibility to
                anyone who has not responded.
                <br />
                <br />
                <strong>5.</strong>{" "}
                <strong>
                  Prize Fulfilment.
                  <br />
                </strong>
                (a) No cash or other alternative prizes are available, except
                that in the event of circumstances outside of their control the
                Promoters (and/or any sponsor partner providing any prize)
                reserve the right to substitute a similar prize of equal or
                greater value. Prizes cannot be transferred or sold, under any
                circumstance but can be transferred if a family member is
                entering on behalf of a child.
                <br />
                (b) Unless otherwise stated in the prize description, winners
                are solely responsible for all personal additional costs and
                expenses associated with claiming the prize.
                <br />
                <br />
                <strong>6.</strong>{" "}
                <strong>
                  Cancellation or Disqualification.
                  <br />
                </strong>
                (a) The Promoters reserve the right to disqualify: <br />
                (i) late, misdirected, incomplete, corrupted, lost, illegible or
                invalid entries; <br />
                (ii) entries where appropriate parental authorisation or a
                requested release form is or was not provided; or <br />
                (iii) entrants whose conduct may bring either of the Promoters
                into disrepute (as the Promoters may decide in their absolute
                discretion).
                <br />
                <br />
                (b) The Promoters reserve the right to modify, suspend, cancel
                or terminate the competition or extend or resume the entry
                period or disqualify any participant at any time without giving
                advance notice and will do so if it cannot be guaranteed that
                the competition can be carried out fairly or correctly for
                technical, legal or other reasons or if the Promoters suspect
                that any person has been manipulating entries or the results, or
                has provided false information or has acted unethically in any
                other way.
                <br />
                <br />
                <strong>7.</strong>{" "}
                <strong>
                  Promoters&rsquo; Liability.
                  <br />
                </strong>
                (a) The Promoters cannot guarantee continuous, uninterrupted or
                secure access to the websites (including their Instagram,
                Facebook, Twitter or other social media pages) and are not
                responsible for any disruption due to technical problems or
                otherwise due to events outside of their reasonable control. In
                the event of technical problems, entries or votes will be void
                and the winner will be selected by a panel of judges or such
                other fair method of selection.
                <br />
                <br />
                (b) The Promoters have arranged this competition in good faith
                but, to the fullest extent allowed by law, accept no
                responsibility for loss or damages as a result of anyone
                participating in the competition or any aspect of any prize
                awarded.
                <br />
                <br />
                (c) The Promoters are not responsible for and do not endorse or
                guarantee, the opinions, views, advice or recommendations posted
                on their websites (including their Instagram, Facebook, Twitter
                or other social media pages).
                <br />
                <br />
                <strong>8.</strong>{" "}
                <strong>Entry through your Facebook account</strong>
                <br />
                If the competition requires entry via Facebook any liability
                incurred by Facebook shall be governed by, and limited to, the
                applicable provisions of the Facebook Statement of Rights and
                Responsibilities. This promotion is not sponsored, endorsed,
                administered or associated with Facebook. By entering you are
                providing your information to the Promoters or their agents and
                not to Facebook. We (and/or our agents) will collect your unique
                Facebook User ID so that we can administer your entry i.e.
                record your entry and randomly select a winner.
              </p>
              <p>
                <strong>9.</strong>&nbsp; &nbsp;&nbsp;
                <strong>
                  Email safety.
                  <br />
                </strong>
                In the event that the Promoters wish to contact you in
                connection with your entry we will do so via an email ending in
                'disney.com' or in &lsquo;theiceco.co.uk&rsquo; or by phone with
                a follow up e-mail. Any email which appears to come from the
                Promoters but using a different ending (e.g. hotmail.com,
                yahoo.com etc) should be treated with suspicion and forwarded to
                Customer Services. DO NOT respond or act on any e-mail if you
                are not sure that it comes from the Promoters. If you are in any
                doubt please contact Customer Services.
                <br />
                <br />
                <strong>10.</strong>&nbsp;
                <strong>
                  &nbsp;Publicity &amp; Filming.
                  <br />
                </strong>
                (a) Winners and/or runners-up may be required to participate in
                related publicity without further payment or permission but you
                agree that the Promoters may publish first name or surname and
                initial, age, town/city and/or likeness.
                <br />
                (b) If filming is required the winners may be asked to consent
                to being filmed (the &ldquo;
                <strong>
                  <em>Contribution</em>
                </strong>
                &rdquo;). If consent is given the winner shall assign to the
                Promoters all rights of whatsoever nature, including all
                copyright in respect of the Contribution and grant all further
                consents or licences necessary to enable the fullest use of the
                Contribution throughout the universe and without any time limit
                by any and all means in any and all media, whether now known or
                hereafter developed, without liability or acknowledgement.
                <br />
                <br />
                <strong>11.</strong>&nbsp;
                <strong>
                  Winners' details.
                  <br />
                </strong>
                The Promoters will, on request, make available information that
                indicates that a valid award took place.&nbsp; For information
                regarding this competition send a stamped and addressed envelope
                to the Promoters quoting &ldquo;Winners List&rdquo; and the name
                of the competition. 12. No purchase necessary. 13. The laws of
                England and Wales apply and any disputes will be dealt with in
                the English courts only.&nbsp;
                <strong>&nbsp;&copy; Disney</strong>
              </p>
            </div> */}
            <div className="terms">
            <p><strong><em>Competition Rules &ndash; Spot the Frozen T4 Bubble Tea Van</em></strong></p>

<p>&nbsp;</p>

<p>We are offering one person the chance to win 4 x tickets to see Frozen The Musical and one month of free bubble teas with T4. <em>See full terms and conditions below.</em></p>

<p>&nbsp;</p>

<table border="1" cellspacing="0">
	<tbody>
		<tr>
			<td >
			<p><strong><em>How to enter:</em></strong></p>
			</td>
			<td >
			<p>If you spot the Frozen The Musical T4 Bubble Tea Van within the promotion period, take a photo of it and post it to either Instagram or Facebook ensuring you tag Frozen London and T4&rsquo;s social media profiles (details below) for your chance to win.&nbsp;</p>

			<p>&nbsp;</p>

			<p>Please ensure that you stop to take the photo in a safe place and do not take a picture whilst driving or standing in the road or any dangerous place.&nbsp; Photos should be of the Frozen London van only.&nbsp; Please see entry restrictions below.</p>

			<p>&nbsp;</p>

			<p>If entering on Instagram, tag: @frozenlondon and @t4_unitedkingdom</p>

			<p>&nbsp;</p>

			<p>If entering on Facebook, tag: @frozenlondon and @t4UnitedKingdom</p>

			<p>&nbsp;</p>

			<p>One winner will be selected across all entries on both social media platforms, ie. one winner from all eligible Instagram and Facebook entries combined.</p>

			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td >
			<p><strong><em>Start Date:</em></strong></p>
			</td>
			<td >
			<p>Wednesday 3rd April 2024, 10am (UK time)</p>

			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td >
			<p><strong><em>Closing Date:</em></strong></p>
			</td>
			<td >
			<p>Monday 14th April 2024, no later than 10am (UK time)</p>

			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td >
			<p><strong><em>Entry Restrictions:</em></strong></p>
			</td>
			<td >
			<p>Entrants must be aged 18 or over and be a resident of the United Kingdom <em>excluding</em> Northern Ireland, the Republic of Ireland, Channel Islands and Isle of Man.</p>

			<p>&nbsp;</p>

			<p>One entry per social media channel only, ie. each person can enter a maximum of 2 times (once on Instagram and once on Facebook).</p>

			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td >
			<p><strong><em>Prize Details:</em></strong></p>
			</td>
			<td >
			<p>One person will win 4 x Frozen The Musical tickets and a T4 voucher worth 30 (one month) free bubble teas. Contents at the sole discretion of the Promoters. Tickets to Frozen The Musical must be redeemed no later than 15 June for an evening performance. Matinees are excluded. Eligible performances include Thursday 7pm, Friday 7pm, Saturday 7pm, and Sunday 5.30pm from 15 April 2024 to 15 June 2024. All attendees must be over the age of 4. The prize does not include travel, food, accommodation, or expenses.</p>

			<p>&nbsp;The T4 bubble tea voucher will consist of 30 single-drink bubble tea credits to be redeemed within 30 days. More than 1 credit can be redeemed in a day. No more than 30 credits can be redeemed within the 30 day period.</p>

			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td >
			<p><strong><em>Winner Selection process</em></strong></p>
			</td>
			<td >
			<p>One winner from the combined entries on Facebook and Instagram will be selected at random from all valid entries received via those social media channels, ie. one winner will be selected from all eligible Instagram and Facebook entries.</p>

			<p>&nbsp;</p>

			<p>The winner will receive confirmation that they have won by being tagged in a post by the Promoters on the social media channel they entered via and were selected from as a winner within ten (10) days of the Closing Date.&nbsp; Winners must send @FrozenLondon and @t4_unitedkingdom a private message on the relevant social media channel with their contact details and postal address within seventy two (72) hours of being notified that they are a winner in order to claim their prize. If no response is received by this time then the prize shall be forfeit and the Promoters reserve the right to award the prize to a substitute winner.&nbsp; The Promoters shall have no liability in respect of the entry initially selected. The Promoter&rsquo;s decision is final and no correspondence will be entered into.</p>

			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td >
			<p><strong><em>Prize Fulfilment:</em></strong></p>
			</td>
			<td >
			<p>Winners will receive their prize within 28 (twenty-eight) days after claiming their prize.</p>

			<p>&nbsp;</p>

			<p>If you are selected as a winner your prize will be posted to you, via recorded delivery, or emailed to you. Please ensure your correct postal address is provided in the private message you send to Frozen The Musical when claiming your prize. Please also provide three date options within the eligibility period for claiming your tickets. Tickets will be allocated subject to availability.</p>

			<p>&nbsp;</p>

			<p>The Walt Disney Company will be responsible for distributing the four tickets to Frozen The Musical at Theatre Royal Drury Lane, London.</p>

			<p>&nbsp;</p>

			<p>Winners must download the T4 app, make a free account and their points will be added to your account to redeem against drinks.</p>

			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td >
			<p><strong><em>Promoters:</em></strong></p>
			</td>
			<td >
			<p>The Promoters of this Competition are:</p>

			<p>&nbsp;</p>

			<p>Disney: The Walt Disney Company Limited (Company number 00530051) whose registered address is located at 3 Queen Caroline Street, London, W6 9PE, United Kingdom</p>

			<p>&nbsp;</p>

			<p>AND</p>

			<p>&nbsp;</p>

			<p>T4: T4 UK Franchise Ltd Room 02-106</p>

			<p>30 Churchill Place London</p>

			<p>E145EU</p>

			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td >
			<p><strong><em>Customer Services/</em></strong></p>

			<p><strong><em>Contact Details:</em></strong></p>
			</td>
			<td >
			<p>If you have any questions please contact the Promoters.</p>

			<p>&nbsp;</p>

			<p>Contact Disney: by emailing at <a href="mailto:help@disney.co.uk">help@disney.co.uk</a> &nbsp;or write to The Walt Disney Company Limited, 3 Queen Caroline Street, London, W6 9PE quoting the name of the competition.</p>

			<p>&nbsp;</p>

			<p>Contact T4: by emailing <a href="mailto:stephanie@shinecreativesolutions.co.uk">stephanie@shinecreativesolutions.co.uk</a> or write to T4: T4 UK Franchise Ltd Room 02-106, 30 Churchill Place London, E145EU quoting the name of the competition.</p>
			</td>
		</tr>
	</tbody>
</table>

<p>&nbsp;</p>

<p>&nbsp;</p>

<table border="1" cellspacing="0">
	<tbody>
		<tr>
			<td >
			<p><strong><em>General Terms &amp; Conditions for Prize Draws and Competitions</em></strong></p>

			<p>By participating you agree to the requirements set out in the Competition Rules, the following General Terms and Conditions and all promotional materials.</p>

			<ol>
				<li><strong>Eligibility.</strong>

				<ol>
					<li>Entrants must meet the Entry Restrictions.</li>
					<li>Not open to employees (or their immediate families) of the Promoters, their agencies or anyone else professionally associated with this competition.</li>
					<li>Maximum one entry per person unless otherwise stated. Entries via agents or third parties or use of multiple identities and email addresses are not permitted. Use of automated entries, votes or other programs is prohibited and all such entries (or votes) will be disqualified.</li>
					<li>Entries must be completed by the Closing Date.</li>
					<li>The Promoters reserve the right at any time to request proof of identity, to verify eligibility<strong> </strong>conditions, or to award any prize to the winner in person or a substitute.</li>
				</ol>
				</li>
				<li><strong>Personal Information. </strong>
				<ol>
					<li>Your personal information will be used in accordance with The Promoters&rsquo; current published Privacy Policy (click <a href="https://disneyprivacycenter.com/">here</a> to view Disney&rsquo;s Policy and click here to view <a href="https://www.theiceco.co.uk/privacy/">The Ice Co&rsquo;s</a> Privacy Policy) and may be passed to our agents to administer the competition or send out prizes. We may also use your personal information to promote the competition and Disney products and services (including the products and services of any partner sponsoring or otherwise participating in the competition) in any media including, without limitation, TV, online and publishing channels and social media platforms.</li>
					<li>Personal information will only be used for direct marketing purposes if you have consented to receive future marketing from the relevant Promoter (you may have already done this if you have registered your personal information with the Promoters previously).</li>
					<li>By entering this competition, entrants agree that their information may be used by&nbsp; the Promoters to administer the promotion and winners consent to give their name and county for the winners&rsquo; list</li>
				</ol>
				</li>
				<li><strong>Winner Selection. </strong>
				<ol>
					<li>The winner(s) will be selected in a random draw to take place within 10 working days of the closing date from all correct/valid entries received.</li>
					<li>The Promoters reserve the right to ask winners to sign a written release, request proof of identity and verify the entry before awarding any prize.</li>
					<li>The Promoters&rsquo; decision to select any winner or runner-up is final and we reserve the right not to correspond on any matter.</li>
				</ol>
				</li>
				<li><strong>Prize Confirmation &amp; Acceptance. </strong>
				<ol>
					<li>If at any time (i) we require a response from any winner before awarding any prize and no response is received after we have made reasonable efforts to make contact and within a reasonable period of time (or by the dates set out in the Competition Rules), or (ii) a winner declines to accept the prize; or (iii) the requested documentation stated in the Competition Rules is not received Disney in time; or (iv) the winner becomes unavailable; The Promoters reserve the right to withdraw the prize entitlement and award the prize to a substitute winner chosen at the same time as the first name(s) were selected or judged to be a winner.</li>
					<li>Any prize which is returned or cannot be delivered will be used in the Promoters&rsquo; sole discretion for another competition or given to charity. Disney has no responsibility to anyone who has not responded.</li>
				</ol>
				</li>
				<li><strong>Prize Fulfilment. </strong>
				<ol>
					<li>No cash or other alternative prizes are available, except that in the event of circumstances outside of their control the Promoters (and/or any sponsor partner providing any prize) reserve the right to substitute a similar prize of equal or greater value. Prizes cannot be transferred or sold, under any circumstance but can be transferred if a family member is entering on behalf of a child.</li>
					<li>Unless otherwise stated in the prize description, winners are solely responsible for all personal additional costs and expenses associated with claiming the prize.</li>
				</ol>
				</li>
				<li><strong>Cancellation or Disqualification. </strong>
				<ol>
					<li>The Promoters reserve the right to disqualify:
					<ol>
						<li>late, misdirected, incomplete, corrupted, lost, illegible or invalid entries;&nbsp;</li>
						<li>entries where appropriate parental authorisation or a requested release form is or was not provided; or</li>
						<li>entrants whose conduct may bring either of the Promoters into disrepute (as the Promoters may decide in their absolute discretion).</li>
					</ol>
					</li>
					<li>The Promoters reserve the right to modify, suspend, cancel or terminate the competition or extend or resume the entry period or disqualify any participant at any time without giving advance notice and will do so if it cannot be guaranteed that the competition can be carried out fairly or correctly for technical, legal or other reasons or if the Promoters suspect that any person has been manipulating entries or the results, or has provided false information or has acted unethically in any other way.</li>
				</ol>
				</li>
				<li><strong>Promoters&rsquo; Liability. </strong>
				<ol>
					<li>The Promoters cannot guarantee continuous, uninterrupted or secure access to the websites (including their Instagram, Facebook, Twitter or other social media pages) and are not responsible for any disruption due to technical problems or otherwise due to events outside of their reasonable control. In the event of technical problems, entries or votes will be void and the winner will be selected by a panel of judges or such other fair method of selection.</li>
					<li>The Promoters have arranged this competition in good faith but, to the fullest extent allowed by law, accept no responsibility for loss or damages as a result of anyone participating in the competition or any aspect of any prize awarded.</li>
					<li>The Promoters are not responsible for and do not endorse or guarantee, the opinions, views, advice or recommendations posted on their websites (including their Instagram, Facebook, Twitter or other social media pages).</li>
				</ol>
				</li>
				<li><strong>Entry through your Facebook account</strong></li>
			</ol>

			<p>If the competition requires entry via Facebook any liability incurred by Facebook shall be governed by, and limited to, the applicable provisions of the Facebook Statement of Rights and Responsibilities. This promotion is not sponsored, endorsed, administered or associated with Facebook. By entering you are providing your information to the Promoters or their agents and not to Facebook. We (and/or our agents) will collect your unique Facebook User ID so that we can administer your entry i.e. record your entry and randomly select a winner.</p>

			<ol>
				<li><strong>Email safety. </strong></li>
			</ol>

			<p>In the event that the Promoters wish to contact you in connection with your entry we will do so via an email ending in &#39;disney.com&#39; or in &lsquo;theiceco.co.uk&rsquo; or by phone with a follow up e-mail. Any email which appears to come from the Promoters but using a different ending (e.g. hotmail.com, yahoo.com etc) should be treated with suspicion and forwarded to Customer Services. DO NOT respond or act on any e-mail if you are not sure that it comes from the Promoters. If you are in any doubt please contact Customer Services.</p>

			<ol>
				<li><strong>&nbsp;Publicity &amp; Filming. </strong>

				<ol>
					<li>Winners and/or runners-up may be required to participate in related publicity without further payment or permission but you agree that the Promoters may publish first name or surname and initial, age, town/city and/or likeness.</li>
					<li>If filming is required the winners may be asked to consent to being filmed (the &ldquo;<strong><em>Contribution</em></strong>&rdquo;). If consent is given the winner shall assign to the Promoters all rights of whatsoever nature, including all copyright in respect of the Contribution and grant all further consents or licences necessary to enable the fullest use of the Contribution throughout the universe and without any time limit by any and all means in any and all media, whether now known or hereafter developed, without liability or acknowledgement.</li>
				</ol>
				</li>
				<li><strong>Winners&#39; details. </strong></li>
			</ol>

			<p>The Promoters will, on request, make available information that indicates that a valid award took place.&nbsp; For information regarding this competition send a stamped and addressed envelope to the Promoters quoting &ldquo;Winners List&rdquo; and the name of the competition.</p>

			<ol>
				<li><strong>No purchase necessary.</strong></li>
				<li><strong>The laws of England and Wales apply and any disputes will be dealt with in the English courts only.</strong></li>
			</ol>

			<p>&nbsp;</p>

			<p><strong>&copy; Disney</strong></p>
			</td>
		</tr>
	</tbody>
</table>


            </div>
          
        </StyledPages>
      </SnowFlakes>
    </Layout>
  );
}
